import React, { FC, useMemo } from 'react'
import Head from 'next/head'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { useRouter } from 'next/router'
import BreadCrumbsData from './base'
import { useNavigation } from '@/utils/navigation/Standard'
import { useMediaQueryContext } from '@/utils/breakpoints/MediaQuery'
import { SizeTypes } from '@/types/size'

export type BreadCrumbProps = {
    title: string
    link?: string
    attachment?: string
}

type BreadCrumbsComponentProps = {
    hasPaddingBottom?: boolean
}

const sportwettenChildTags = ['602', '621', '622', '623']

const BreadCrumbs: FC<BreadCrumbsComponentProps> = ({ hasPaddingBottom }) => {
    const { navigation } = useNavigation()
    const { currentDevice } = useMediaQueryContext()
    const shouldRenderMobileBreadCrumbs =
        currentDevice === SizeTypes.MOBILE ||
        currentDevice === SizeTypes.TABLET ||
        currentDevice === undefined
    // useRouter and mock from breadcrumb tests should be removed after we will get rid of event teaser
    const { asPath } = useRouter()

    const breadCrumbs = useMemo(() => {
        const desktop: BreadCrumbProps[] = []
        let mobile: BreadCrumbProps[] = []
        desktop.push({ title: 'Home', link: '/' })
        mobile.push({ title: 'Home', link: '/' })
        if (navigation?.tag?.parent) {
            desktop.push({
                title: navigation?.tag?.parent.title,
                link: navigation?.tag?.parent.href,
            })
            if (!navigation?.breadCrumbs?.pageTitle) {
                mobile.push({
                    title: navigation?.tag?.parent.title,
                    link: navigation?.tag?.parent.href,
                })
            }
        }

        if (navigation?.tag) {
            const tagBreadcrumb = {
                title: navigation?.tag.title,
                link: navigation?.tag.href,
                attachment: sportwettenChildTags.includes(navigation.tag.contextId)
                    ? ' | Anzeigen'
                    : undefined,
            }
            desktop.push(tagBreadcrumb)
            mobile.push(tagBreadcrumb)
        }

        if (navigation?.breadCrumbs) {
            if (navigation.breadCrumbs.additionalBreadCrumbs?.length) {
                const additionalBreadCrumbs = navigation.breadCrumbs.additionalBreadCrumbs
                desktop.push(...additionalBreadCrumbs)
                mobile.push(...additionalBreadCrumbs)
            }

            if (navigation.breadCrumbs.pageTitle) {
                const articleBreadCrumb = {
                    title: navigation.breadCrumbs.pageTitle,
                }
                desktop.push(articleBreadCrumb)
                mobile.push(articleBreadCrumb)
            }
        }

        mobile = mobile.slice(-2)
        return {
            desktop,
            mobile,
        }
    }, [navigation])

    const breadcrumbList = useMemo(() => {
        return {
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: breadCrumbs.desktop.map((crumb, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                name: crumb.title,
                ...(crumb.link
                    ? { item: `https://www.sport1.de${crumb.link}` }
                    : { item: `https://www.sport1.de${asPath}` }),
            })),
        }
    }, [breadCrumbs.desktop, asPath])

    return (
        <>
            <Head>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbList) }}
                />
            </Head>
            <NonFlexingContainer
                paddingX="spacing-6"
                paddingTop="spacing-4"
                paddingBottom={
                    hasPaddingBottom
                        ? ['spacing-6', 'spacing-6', 'spacing-9', 'spacing-9']
                        : undefined
                }
            >
                {!shouldRenderMobileBreadCrumbs && (
                    <NonFlexingContainer centerVertical horizontal>
                        <BreadCrumbsData breadCrumbs={breadCrumbs.desktop} />
                    </NonFlexingContainer>
                )}
                {shouldRenderMobileBreadCrumbs && (
                    <NonFlexingContainer centerVertical horizontal>
                        <BreadCrumbsData breadCrumbs={breadCrumbs.mobile} />
                    </NonFlexingContainer>
                )}
            </NonFlexingContainer>
        </>
    )
}

export default BreadCrumbs
